<template>
  <edit-template @confirm="submit" :confirm-loading="loading" flag="add" :data-loading="dataLoading">
    <el-form :model="form" ref="form" label-width="120rem" :rules="rules">
      <el-form-item label="收费方案名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" :maxlength="20"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="方案分类" prop="category">
            <el-select v-model="form.category" placeholder="请选择">
              <el-option v-for="item in category" :key="item.id" :label="item.category_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="业务类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" @change="handleBusinessChange">
              <el-option v-for="item in business" :key="item.id" :label="item.type_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="缴费方式" prop="mode">
            <el-select v-model="form.mode" placeholder="请选择">
              <el-option v-for="item in types" :key="item.id" :label="item.mode_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="入账方式" prop="income_method">
            <el-select v-model="form.income_method" placeholder="请选择">
              <el-option v-for="item in income_option" :key="item.id" :label="item.income_method_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="8" :xs="8" v-if="form.income_method === 2">
          <el-form-item label="入账校区" prop="school_id">
            <el-select v-model="form.school_id" placeholder="请选择">
              <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="收费计划" prop="combination">
        <span slot="label" style="font-size: 20rem;color: #1E3AA2;">收费计划</span>
        <el-button type="primary" :disabled="form.combination.length>=36" size="large" plain @click="visible=1">添加收费计划</el-button>
      </el-form-item>
      <el-form-item label="总金额">
        {{amount}}
      </el-form-item>
    </el-form>
    <div class="plan">
      <plan class="plan-item" v-for="(item,index) in form.combination" :data="item" :key="index" @on-delete="onDeletePlan(index)" @on-edit="onEditPlan(index)"></plan>
    </div>

    <el-dialog :visible="!!visible" :title="visible === 1?'添加':'编辑'" @close="onClose" :close-on-click-modal="false">
      <el-form :model="plan" ref="plan" label-width="120rem" :rules="planRules">
        <el-form-item label="收费学年" prop="school_year_id">
          <el-select v-model="plan.school_year_id" placeholder="请选择" :disabled="visible===2" @change="onChangePlan($event,0)">
            <el-option v-for="item in grade" :key="item.id" :label="item.school_year_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费日期" prop="charge_date">
          <el-date-picker
              v-model="plan.charge_date"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="请选择">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收费科目组合" prop="id">
          <el-select v-model="plan.id" placeholder="请选择"  @change="onChangePlan($event,1)" :disabled="visible===2">
            <el-option v-for="item in subjects" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应付金额">
          {{plan.price || "0.00"}}
        </el-form-item>
        <el-form-item label="实付金额" prop="actual_price">
          <el-input-number v-model="plan.actual_price" :min="0" :max="1000000" :step="0.01" :precision="2" placeholder="请输入"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="onClose">取消</el-button>
        <el-button type="primary" @click="onPlanConfirm">确定</el-button>
      </div>
    </el-dialog>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit"
import plan from "./components/plan"
export default {
  _config: {route: {path: "add", meta: {title: "新增"}}},
  components: {EditTemplate,plan},
  data() {
    return {
      loading: false,
      dataLoading:false,
      // 方案分类
      category: [],
      // 入账方式
      income_method: [],
      income_option: [],
      // 业务类型
      business:[],
      // 缴费方式
      types:[],
      // 入账校区
      schools:[],
      // 收费学年
      grade:[],
      // 收费科目组合
      subjects:[],
      // 弹窗显示: 0 隐藏 , 1 添加 ,2 编辑
      visible:0,
      // 收费计划, 临时数据
      plan:{
        school_year_id:null,
        charge_date:null,
        id:null,
        actual_price:null
      },
      // 编辑索引
      index:-1,
      // 表单数据
      form: {
        name:null,
        type:null,
        mode:null,
        school_id:'',
        income_method: '',
        category: '',
        combination:[]
      },
      // 表单验证规则
      rules: {
        category:[{required:true,message:"请选择方案分类",trigger:"change"}],
        income_method:[{required:true,message:"请选择入账方式",trigger:"change"}],
        name:[{required:true,message:"请输入收费方案名称",trigger:"blur"}],
        type:[{required:true,message:"请选择业务类型",trigger:"change"}],
        mode:[{required:true,message:"请选择缴费方式",trigger:"change"}],
        school_id:[{required:true,validator(rule,value,callback){
            if (value.length===0)callback(new Error("请选择入账校区"))
            else callback();
          },trigger:"change"}],
        combination:[{required:true,validator(rule,value,callback){
            if (value.length===0)callback(new Error("最少创建1个收费计划"))
            else callback();
          },trigger:"change"}]
      },
      // 添加收费计划表单验证规则
      planRules:{
        school_year_id:[{required:true,message:"请选择收费学年",trigger:"change"}],
        charge_date:[{required:true,message:"请选择收费日期",trigger:"change"}],
        id:[{required:true,message:"请选择收费科目组合",trigger:"change"}],
        actual_price:[{required:true,message:"请输入实付金额",trigger:"blur"}],
      },
      pickerOptions:{
        disabledDate(e){
          let now = (new Date()).getFullYear();
          return Math.abs(now - e.getFullYear()) > 3;
        }
      }
    }
  },
  computed:{
    amount(){
      let count = 0;
      this.form.combination.forEach(item=>{
        count += parseFloat(item.actual_price);
      })
      return count.toFixed(2);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataLoading = true;
      this.$_register.get("api/finance/fee-plan/get-check").then(res=>{
        let {mode,school,fee_plan_type,school_year,category,income_method} = res.data.data;
        this.schools = school;
        this.types = mode;
        this.business = fee_plan_type;
        this.grade = school_year;
        this.category = category;
        this.income_method = income_method;
        // 获取组合
        this.$_register.get("api/finance/fee-plan/get-combination").then(res=>{
          this.subjects = res.data.data;
        }).finally(()=>this.dataLoading = false)
      }).catch(res=>this.dataLoading = false)
    },
    onClose(){
      this.plan = {};
      this.visible=0;
      this.$refs.plan.resetFields();
    },
    onPlanConfirm(){
      this.$refs.plan.validate(flag=>{
        if (flag){
          let plan = JSON.parse(JSON.stringify(this.plan))
          if (this.visible === 1)
            this.form.combination.push(plan);
          else this.$set(this.form.combination,this.index,plan)
          this.plan = {};
          this.visible = 0;
          this.$forceUpdate();
          this.$refs.form.validateField("combination");
        }
      })

    },
    submit() {
      this.$refs.form.validate(flag=>{
        if (flag){
          const { income_method, school_id, ...other } = this.form
          const data = { ...other, income_method, school_id: income_method === 2 ? school_id : 0 }
          this.loading = true;
          this.$_register.post("api/finance/fee-plan/store",data).then(res=>{
            this.$router.back();
            this.$message.success("新增成功")
          }).finally(()=>this.loading = false)
        }
      })
    },
    onDeletePlan(index){
      this.form.combination.splice(index,1);
      this.$refs.form.validateField("combination");
    },
    onEditPlan(index){
      this.plan = JSON.parse(JSON.stringify(this.form.combination[index]));
      this.index = index;
      this.visible = 2;
    },
    /**
     * 计划中的选择框数据被更改
     * @param e
     * @param {0|1} type 0: 年级, 1: 科目组合
     */
    onChangePlan(e,type){
      if (type === 0)
        this.plan.school_year_name = this.grade.getOption(e).school_year_name;
      else {
        let subject = this.subjects.getOption(e)
        this.plan.price = subject.price;
        this.plan.fee_combination_name = subject.name;
      }
    },
    handleBusinessChange(val) {
      const arr = [[0,1,2], [1], [0,2]]
      const arr2 = arr[val] || []
      const list = this.income_method.filter(item=>arr2.includes(item.id))
      this.income_option = list;
      this.form.income_method = ''
      this.$nextTick(() =>this.$refs['form'].clearValidate(['income_method']))
    }
  }
}
</script>

<style lang="scss" scoped>
.plan{
  display: grid;
  grid-gap: 48rem;
  grid-template-columns: repeat(4,1fr);
}
</style>
